module.exports = [{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-google-tagmanager-virtual-0d85a92852/0/cache/gatsby-plugin-google-tagmanager-npm-5.13.0-6ef9bd033b-b67a6c7f38.zip/node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}},"id":"GTM-PH6VG88","includeInDevelopment":false,"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com","selfHostedPath":"gtm.js"},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../.yarn/unplugged/gatsby-virtual-9dd18e17ca/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
