import '@seed-design/stylesheet/global.css'
import './src/styles/global.css'
import 'simple-reveal/index.css'

import '@daangn/sprout-components-button/index.css'

export const onRouteUpdate = ({ location }) => {
  if (location.pathname !== '/expired') {
    window.location.href = '/expired'
  }
}
